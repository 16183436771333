@use '../../styleguide/vars.scss';

.gdpr {
  background-color: hsla(0, 0%, 100%, 0.9);
  bottom: 0;
  flex-flow: row;
  padding-bottom: 10px;
  padding-top: 10px;
  position: fixed;
  width: 100%;
  z-index: 1;

  @media screen and (min-width: vars.$extra-large) {
    bottom: 2%;
    left: 2%;
    padding: 15px;
    width: 40%;
  }

  @media screen and (min-width: vars.$extra-extra-large) {
    width: 30%;
  }
}

.accept {
  margin-bottom: 15px;

  @media screen and (min-width: vars.$extra-large) {
    margin-bottom: 0;
  }
}

.gdprContent {
  margin-bottom: 15px;
  display: flex;
  gap: 16px;
  align-items: center;

  & svg {
    width: 122px;
    fill: none;
  }

  &Text {
    width: calc(100% - 138px);
  }
}

.noLine {
  text-decoration: none;
}
